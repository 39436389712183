body{
  /* background-color: #333333 !important; */
  background-image: url("./assets/img/wooden2.jpg");
  overflow-x: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.makeStyles-cardBody-130{
  transition: all 3s ease-in-out !important;
}

.makeStyles-card-120{
  transition: all 3s ease-in-out !important;
}

.menuHeader{
  width: 100%;
  height: 100%;
  margin:-2% 0px;
}
#Menu{
  margin-top: 4%;
}
.AddShopingCartButton{
  align-items: center !important;
  align-content: center !important;
  text-align: center !important;
  justify-content: center !important;
  justify-self: center !important;
  justify-items: center !important;
}
.Price{
  align-items: center !important;
  align-content: center !important;
  text-align: center !important;
  justify-content: center !important;
  justify-self: center !important;
  justify-items: center !important;
}
.cardNAmeHeader{
  padding: 0rem 6px !important;
  
  align-items: center !important;
  align-content: center !important;
  text-align: center !important;
  justify-content: center !important;
  justify-self: center !important;
  justify-items: center !important;
}
/* .priceForCart{
  margin-left: 40%;
} */
.cartLengh{
  height: 70vh;
  align-items: center !important;
  align-content: center !important;
  text-align: center !important;
  justify-content: center !important;
  justify-self: center !important;
  justify-items: center !important;
}
.cartScroll{
  overflow-y: scroll;
}
.Center{
  align-items: center !important;
  align-content: center !important;
  text-align: center !important;
  justify-content: center !important;
  justify-self: center !important;
  justify-items: center !important;
}
h4 span{
  float: right;
}
.makeStyles-primaryCardHeader-129,.makeStyles-primaryCardHeader-133,.makeStyles-cardHeader-127,.makeStyles-primary-52{
 
  
  box-shadow: 0 12px 20px -10px rgba(80, 80, 80, 0.28), 0 4px 20px 0px rgba(173, 173, 173, 0.12), 0 7px 8px -5px rgba(197, 197, 197, 0.2) !important;
}

.CheckoutButton{
  color:#E8B03B !important;
}
.AddonDialogButton{
  box-shadow: 0 12px 20px -10px rgba(80, 80, 80, 0.28), 0 4px 20px 0px rgba(173, 173, 173, 0.12), 0 7px 8px -5px rgba(197, 197, 197, 0.2) !important;
}
.makeStyles-main-115 {
  margin-top:5% !important;
}
.makeStyles-button-50,
.makeStyles-danger-56 .MuiButton-label ,
.makeStyles-success-54 .MuiButton-label,
.makeStyles-success-59 .MuiButton-label,
.makeStyles-success-59 .MuiButton-label,
.makeStyles-danger-61 .MuiButton-label{
  color: white !important;
}
.buttonCenter{
   margin-left: auto;
   margin-right: auto;
  width: 100%;
}
.buttonCenter button{
  margin-left: 5%;
  margin-right:5%;
  width: 40%;
}
.MuiButton-label img{
  width: 200%;
}
.makeStyles-button-50 a{
 color: white;
}
.makeStyles-button-50 a:hover{
  color: white;
 }
 .MuiFormControl-root{
   width:100%;
 }
 .verticalLine{
   border: #E0E0E0 solid 2px;
   height:100%;
   padding:5%
 }
/* .MuiButton-label{
  color: initial;
} */
.MuiFormControl-root{
  width:100%
}
.makeStyles-tabRootButton-121 {
  color: #E8B03B !important;}
  .fontSetting{
    font-family: 'Cairo', sans-serif !important;
  }
  .confimationImage{
    height:60%;
    width:60%;
    margin-left: 20%;
    justify-content: center;
  }
  .MuiAppBar-colorPrimary{
    color: #E8B03B !important;
    background-color: #10151E !important;
    
  }
 .DasboardTable{
   margin-top: 2%;
   margin-right: 1%;
   margin-left: 1%;
 }
 .MuiButton-label{
  color: #E8B03B !important;
 }
 .center{
     height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
 }
 .color-white{
   color: white !important;
 }
 .fot-margin{
       margin-top: -6%;
 }
 .MuiInputBase-formControl{
   margin-bottom: 5%;
 }
 .MuiList-padding{
   text-align: justify;
  }
 .FloatingButton{
  margin: 0px !important;
  top: auto !important;
  right: 20px !important;
  bottom: 20px !important;
  left: auto !important;
  position: fixed !important;
 }
 .OrderID{
   color: green !important;
 }

